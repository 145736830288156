<template>
  <CHeader fixed with-subheader light >  
  
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
     
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand>
    
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          All India
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/State/Kerala" exact>
          Kerala
        </CHeaderNavLink>
      </CHeaderNavItem>
      
    </CHeaderNav>
   
    <CHeaderNav class="mr-4">
    
      <CHeaderNavItem class="d-md-down-none mx-2">
         
        <a href='https://github.com/manojap/vue-coreui-dashboard'>
          <CIcon name="cib-github"/></a>
     
      </CHeaderNavItem>
       
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
 
    </CHeaderNav>
    <CSubheader class="px-3">    
      <CBreadcrumbRouter class="border-0 mb-0"/>      
      <CCard align="center" tag="header" class="border-0 ml-auto my-2" >
      <h5>{{this.$store.state.title.toUpperCase()}} COVID DASHBOARD  </h5>
      </CCard>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  }
}
</script>
